import { Box, Typography } from '@mui/material'
import React from 'react'

const InfoCard = ({ image, title, value, gap, width, height, imageStyle, svg }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: gap,
        width: {
          xs: '145px'
        }
      }}
    >
        {image && !svg && <img style={{objectFit: 'contain', ...imageStyle}} src={image} alt="Icon" width={width} height={height}/>}
        {svg && svg}
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '16px'
          },
          fontFamily: 'Montserrat, Sans-serif'
        }}>{title}</Typography>
        <Typography sx={{
          fontSize: {
            xs: '16px',
            md: '16px'
          },
          fontFamily: 'Montserrat, Sans-serif'
        }} >{value}</Typography>
    </Box>
  )
}

export default InfoCard