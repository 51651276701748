import { Apartment, Map, OpenWith, TableRows } from '@mui/icons-material'
import { Box, Button, Divider, Typography } from '@mui/material'
import React from 'react'
import { handleDetailModalData, handleDetailModalState } from '../../features/afarizmi/AfarizmiSlice'
import { useDispatch } from 'react-redux'
import InfoCard from '../modal/components/InfoCard'
import { buildings, floorIcon, keyIcon, sqaureIcon } from '../../utils/iconSvg'

const Card = ({imgUrl, name, square, buildingName, floor, status, item}) => {
    const dispatch = useDispatch()
    let imgPathName = item.planMetric?.name.split(',');
    let imgPathUrl = item.planMetric?.url.split(',');

  return (
    <Box onClick={() => {
        dispatch(handleDetailModalState(true))
        dispatch(handleDetailModalData(item))
    }} sx={{height: '500px',border: '2px solid #C7C7C7', cursor: 'pointer', ':hover': {backgroundColor: '#ededed', transform: 'translateY(-1px)', }}} borderRadius={3} display={'flex'} flexDirection={'column'} padding={2} >
        <Box>
            <img width={'100%'} height={'227px'} src={imgPathName !== undefined && imgPathUrl !== undefined ? imgPathUrl.at(imgPathName.findIndex(item => item === 'card')) : ''} alt="" loading="lazy"/>
        </Box>
        <Box flex={1} display={'flex'} flexDirection={'column'}>
            <Box my={1} display={'flex'} alignItems={'center'} flexDirection={'column'}>
                <Box sx={{
                    display: 'flex',
                }}>
                <InfoCard 
                    value={buildingName}
                    width={'35px'}
                    gap={1}
                    height={'35px'}
                    
                    svg={buildings({width: '35px', height: '35px'})}
                />
                <InfoCard
                value={`Lokali ${name}`}
                gap={1}
                width={'35px'}
                height={'35px'}
                svg={keyIcon({width: '35px', height: '35px'})} />
                </Box>
                <Box sx={{
                    display: 'flex',
                    mt: 2,
                    

                }}>
                <InfoCard 
                    value={floor}
                    gap={1}
                    width={'35px'}
                    height={'35px'}
                    svg={floorIcon({width: '35px', height: '35px'})} />
                <InfoCard
                value={square}
                gap={1}
                width={'35px'}
                height={'35px'}
                svg={sqaureIcon({width: '35px', height: '35px'})}
                 />
                </Box>
            </Box>
            <Box>
                <Divider sx={{my: 2}} />
                <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    
                    <Button style={{textTransform: 'none'}} sx={{
                        color: 'white',
                        width: '100%',
                        background: '#1D2934',
                        ":hover": {
                            background: '#1D2931'
                        }
                    }}>Më Shumë</Button>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default Card