import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { Menu } from '@mui/icons-material';
import { mainDarkColor } from '../../utils/consts';
import { useNavigate } from 'react-router-dom';


const drawerWidth = 240;
const navItems = ['Afarizmi', 'Zgjedh Banesen', 'Galeria', '3D'];
const navLinks = ['', 'https://international-residence.com/zgjidh-banesen/', 'https://international-residence.com/galeria/', 'https://international-residence.com/3d/'];
// const darkTheme = createTheme({
//   palette: {
//     mode: 'light',
//     primary: {
//       main: 'red',
//     },
//   },
// });

export default function Navbar(props) {
  const { window: windows } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const container = windows !== undefined ? () => windows().document.body : undefined;
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const appBarLabel = (
    <Toolbar>
      <Box sx={{display: {xs: 'flex', lg: 'none'}, flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
      <Box>
          <img width={200} src='/logo.png' />
        </Box>
        <IconButton onClick={handleDrawerToggle} sx={{display: {xs: 'block', lg: 'none'}, color: 'white'}}>
        <Menu />
      </IconButton>
      </Box>
      <Box width={'100%'} justifyContent={'space-between'} sx={{display: {xs: 'none', lg: 'flex'}, alignItems: 'center'}}>
        <Box>
          <img width={200} src='/logo.png' />
        </Box>
        <Box mr={20} display={'flex'} justifyContent={'space-between'} width={'40%'}>
            <Box display={'flex'}>
                <Button sx={{color: 'white'}}>Afarizmi</Button>
                <Button onClick={() => window.location.assign('https://international-residence.com/zgjidh-banesen/')} sx={{color: 'white'}}>Zgjedh Banesën</Button>
                <Button onClick={() => window.location.assign('https://international-residence.com/galeria/')} sx={{color: 'white'}}>Galeria</Button>
                <Button onClick={() => window.location.assign('https://international-residence.com/3d/')} sx={{color: 'white'}}>3D</Button>
            </Box>
            <Box>
                <Button
                onClick={() => window.location.assign('https://international-residence.com/na-kontaktoni/')}
                sx={{
                  color: '#F09133',
                  borderColor: '#F09133',
                  ":hover" : {
                    borderColor: '#F09133'
                  }
                }} variant='outlined'>Na kontaktoni</Button>
            </Box>
        </Box>
      </Box>
    </Toolbar>
  );
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        Afarizmi
      </Typography>
      <Divider sx={{backgroundColor: 'white'}}/>
      <List>
        {navItems.map((item, index) => (
          <ListItem key={item} disablePadding onClick={() => {
            window.location.replace(navLinks[index])
          }}>
            <ListItemButton sx={{ textAlign: 'center' }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
  return (
    <Stack spacing={2} sx={{ flexGrow: 1 }}>
        <AppBar position="fixed" sx={{backgroundColor: '#1D2934'}}>
          {appBarLabel}
        </AppBar>
        <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', lg: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: mainDarkColor, color: 'white' },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
    </Stack>
  );
}