import { Box, Checkbox, FormControlLabel, FormGroup, Grid, Modal, Fade, Slider, Typography, useMediaQuery, useTheme, IconButton, Divider, Button } from '@mui/material'
import React, { useState } from 'react'
import FilteredCard from './FilteredCard';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterCheckedData, getFilterMobileModalState, handleFilter, handleMobileModal, handleResetFilter, handleSquareChange } from '../../../features/filter/FilterSlice';
import { Close, Filter1, FilterAlt, KeyboardArrowDown, Refresh } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import Menu from '@mui/material/Menu';
import SliderComponent from './SliderComponent';
import { mainDarkColor } from '../../../utils/consts';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      PaperProps={{
        sx: {
          width: '100%',
          marginLeft: {
            xs: -2,
            md: 0,
          },        
          marginRight: 5}
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      {...props}
    />
  ))(({ theme, widthNumber }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      padding: 20,
      width: widthNumber,
      marginTop: theme.spacing(1),
      minWidth: 150,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
       
      },
    },
  }));

const MobileFilter = () => {
const theme = useTheme();
  const isSmallDev = useMediaQuery(theme.breakpoints.down('md'));
  const dispatch = useDispatch();
  const checkedFilter = useSelector(getFilterCheckedData);
  const mobileModal = useSelector(getFilterMobileModalState);
  const [filter, setFilter] = useState({
    'Ne Zhvillim': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Object': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Floor': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
    'Square': {
      checked: false,
      opened: false,
      anchorEl: null,
    },
  })

  const filterLabelStyle = {
    fontSize: isSmallDev ? "18px" : "1.4rem",
    fontFamily: "Montserrat",
    color: 'white'
  };
  const infoTextStyle = {
    fontSize: isSmallDev ? "12px" : "1.1rem",
    fontFamily: "Montserrat",
    color: 'white'
  };
  const handleFilterState = (e, s) => {
    switch(s){
      case 'object':
        setFilter({
          ...filter,
          "Object" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'devState':
        setFilter({
          ...filter,
          "Ne Zhvillim" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'floor':
        setFilter({
          ...filter,
          "Floor" : {
            opened: true,
            anchorEl: e.target,

          }
        })
        break;
      case 'square':
        setFilter({
          ...filter,
          "Square" : {
            opened: true,
            anchorEl: e.target,
          }
        })
        break;
    }
  }
  const handleObjectFilter = (name) => {
    if(checkedFilter.length < 1) {
      dispatch(
        handleFilter({
          name: name,
          isChecked: true,
        }));
        return;
    }
    if(!checkedFilter.find((item) => 
      item.name === name
    )) {
      dispatch(
        handleFilter({
          name: name,
          isChecked: true,
        }));
        return;
    }
    checkedFilter.forEach((item) => {
      if(item.name === name) {
        if(item.isChecked){
          dispatch(
            handleFilter({
              name: name,
              isChecked: false,
            }));
            return;
        }
        else {
          dispatch(
            handleFilter({
              name: name,
              isChecked: true,
            }));
            return;
        }
      }
      else {
          return;
      }
    })
   
  }
    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -30%)",
        width: '95%',
        height: '95%',
        bgcolor: "background.paper",
        boxShadow: 40,
        borderRadius: 5,
        p: 1,
        overflow: 'auto',
        
      };

      const handleChangeSquare = (event, newValue) => {
        dispatch(handleSquareChange(newValue));
      };

      return (
        <>
          <h1></h1>
          <Modal open={mobileModal} closeAfterTransition>
            <Fade in={mobileModal}>
              <Box sx={{ ...style, transition: "opacity 0.9s ease-in-out" }}>
                <Box width={"100%"} overflow={"hidden"}>
                  <Box
                    sx={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Typography variant="h6" fontStyle={"Montserrat"}>
                      Filteri
                    </Typography>
                    <IconButton
                      onClick={() => {
                        dispatch(handleMobileModal(false));
                      }}
                    >
                      <Close />
                    </IconButton>
                  </Box>
                  <Divider sx={{ my: 1 }} />
                  <Box
                    height={checkedFilter?.length < 1 && "0px"}
                    display={"flex"}
                    flexWrap={"wrap"}
                    columnGap={1}
                  >
                    {checkedFilter?.map((item, index) => {
                      if (
                        item.isChecked === true &&
                        item.name !== "allFloor" &&
                        item.name !== "allObj"
                      ) {
                        return <FilteredCard key={index} title={item.name} />;
                      }
                    })}
                  </Box>
                  <Grid container mt={1} spacing={isSmallDev ? 1.3 : 3}>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                          display: "flex",
                          position: "relative",
                          justifyContent: "space-between",
                          color: "black",
                          alignItems: "center",
                          borderRadius: "5px",
                          py: 1,
                        }}
                        onClick={(e) => {
                          handleFilterState(e, "object");
                        }}
                      >
                        <Typography
                          sx={{ ...filterLabelStyle, color: "black" }}
                        >
                          Objekti
                        </Typography>
                        <KeyboardArrowDown />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                          display: "flex",
                          position: "relative",
                          justifyContent: "space-between",
                          color: "black",
                          alignItems: "center",
                          borderRadius: "5px",
                          py: 1,
                        }}
                        onClick={(e) => {
                          handleFilterState(e, "devState");
                        }}
                      >
                        <Typography
                          sx={{ ...filterLabelStyle, color: "black" }}
                        >
                          Faza Ndërtimit
                        </Typography>
                        <KeyboardArrowDown />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                          display: "flex",
                          position: "relative",
                          justifyContent: "space-between",
                          color: "black",
                          alignItems: "center",
                          borderRadius: "5px",
                          py: 1,
                        }}
                        onClick={(e) => {
                          handleFilterState(e, "floor");
                        }}
                      >
                        <Typography
                          sx={{ ...filterLabelStyle, color: "black" }}
                        >
                          Kati
                        </Typography>
                        <KeyboardArrowDown />
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} lg={3}>
                      <Box
                        sx={{
                          width: "100%",
                          backgroundColor: "rgba(0, 0, 0, 0.0)",
                          borderBottom: "1px solid black",
                          cursor: "pointer",
                          display: "flex",
                          position: "relative",
                          justifyContent: "space-between",
                          color: "black",
                          alignItems: "center",
                          borderRadius: "5px",
                          py: 1,
                        }}
                        onClick={(e) => {
                          handleFilterState(e, "square");
                        }}
                      >
                        <Typography
                          sx={{ ...filterLabelStyle, color: "black" }}
                        >
                          Siperfaqja
                        </Typography>
                        <KeyboardArrowDown />
                      </Box>
                    </Grid>
                  </Grid>
                  <StyledMenu
                    id="devState"
                    anchorEl={filter["Ne Zhvillim"].anchorEl}
                    open={filter["Ne Zhvillim"].opened}
                    onClose={() => {
                      setFilter({
                        ...filter,
                        "Ne Zhvillim": {
                          ...filter["Ne Zhvillim"],
                          opened: false,
                        },
                      });
                    }}
                  >
                    <Box>
                      <FormGroup>
                        <FormControlLabel
                          label="Ne Zhvillim"
                          control={
                            <Checkbox
                              onChange={(e) => {
                                dispatch(
                                  handleFilter({
                                    name: e.target.name,
                                    isChecked: e.target.checked,
                                  })
                                );
                              }}
                              name="Ne Zhvillim"
                              checked={
                                checkedFilter.find(
                                  (item) => item.name === "Ne Zhvillim"
                                )
                                  ? checkedFilter.find(
                                      (item) => item.name === "Ne Zhvillim"
                                    ).isChecked
                                  : false
                              }
                            />
                          }
                        />
                      </FormGroup>
                    </Box>
                  </StyledMenu>
                  <StyledMenu
                    anchorEl={filter["Object"].anchorEl}
                    widthNumber={500}
                    open={filter["Object"].opened}
                    onClose={() => {
                      setFilter({
                        ...filter,
                        Object: { ...filter["Object"], opened: false },
                      });
                    }}
                  >
                    <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                      viewBox="0 0 500 400" xmlSpace="preserve">
                      <path 
                      onClick={() => {
                        handleObjectFilter('Objekti 2')
                      }}
                      onMouseEnter={() => {
                        document.getElementById('txt2').classList.add('object-filter-text-active')
                      }}
                      onMouseLeave={() => {
                        document.getElementById('txt2').classList.remove('object-filter-text-active')
                      }} className={checkedFilter.find((item) => item.name.includes('Objekti 2') && item.isChecked) ? 'object-filter-selected' : 'object-filter-select'} d="M246.5,47.9l-0.6,3.5v2.3H241l-0.3,2.9c0,0-0.2,17.6-0.2,17.6s3.4,0,3.4,0s0,6.7,0,6.7c0.1,0,2,0,2,0v0.8h-1.6
                        l0,1H244l0,6.7h-3.1l-0.4,1.9l-0.1,17.3l5.3,0.1l0,5.4l16.9,0.1l1.1-1.4v-2.5l7.1,0v-0.5l0.7,0v-0.9l1.3,0.1v1.6l7.9,0.1v3.3
                        l16.9,0.2l2.6-1.4v-3.6l2.3,0l2.7-1.6V90l-3.8,0v-7.5l-0.7,0l0-1.7l0.2,0l0-1h1V74l1.3,0l2.6-2.9l0.1-17.6l-5.2-0.1l0-0.7l-0.7,0v-4
                        h-17.7l-2,3.8l-8.3,0.1V52l-7.7-0.1l0-4H246.5z"/> 
                      <path 
                      onClick={() => {
                        handleObjectFilter('Objekti 7')
                      }}
                      onMouseEnter={() => {
                        document.getElementById('txt7').classList.add('object-filter-text-active')
                      }}
                      onMouseLeave={() => {
                        document.getElementById('txt7').classList.remove('object-filter-text-active')
                      }} className={checkedFilter.find((item) => item.name.includes('Objekti 7') && item.isChecked) ? 'object-filter-selected' : 'object-filter-select'} d="M312.9,189.3l1.4,3.3l1.3,1.9l-4.1,2.7l1.3,2.5c0,0,9.6,14.8,9.7,14.7c0-0.1,2.8-1.9,2.8-1.9s3.7,5.6,3.8,5.5
                        c0.1-0.1,1.6-1.1,1.6-1.1l0.5,0.7l-1.4,0.9l0.5,0.8l-0.2,0.2l3.7,5.5l-2.6,1.8l0.7,1.8l9.5,14.4l4.5-2.8l3,4.5l14.1-9.3l0.2-1.8
                        l-1.4-2.1l5.9-3.9l-0.3-0.4l0.5-0.4l-0.5-0.7l1.1-0.7l0.9,1.3l6.6-4.3l1.8,2.7l14.1-9.3l1.4-2.6l-2-3l1.9-1.2l1.3-2.8l-9.8-14.6
                        l-3.2,2.1l-4.2-6.2l-0.6,0.3l-1-1.4l0.2-0.1l-0.5-0.9l0.8-0.6l-3.2-4.7l1.1-0.8l0.6-3.8l-9.7-14.7l-4.4,2.8l-0.3-0.6l-0.6,0.4
                        l-2.2-3.3l-14.7,9.9l0.5,4.3l-6.9,4.7l-0.3-0.5l-6.4,4.2l-2.3-3.3L312.9,189.3z"/>
                      <path 
                      onClick={() => {
                        handleObjectFilter('Objekti 6')
                      }}
                      onMouseEnter={() => {
                        document.getElementById('txt6').classList.add('object-filter-text-active')
                      }}
                      onMouseLeave={() => {
                        document.getElementById('txt6').classList.remove('object-filter-text-active')
                      }} className={checkedFilter.find((item) => item.name.includes('Objekti 6') && item.isChecked) ? 'object-filter-selected' : 'object-filter-select'} d="M249.3,162.8l-2.1,2.8l-1.1,2.1l-4.4-2.2l-1.5,2.4c0,0-8.1,15.6-8.1,15.6c0.1,0,3.1,1.5,3.1,1.5s-3,6-3,6
                        s1.7,0.9,1.7,0.9l-0.4,0.7l-1.5-0.7l-0.4,0.9l-0.2-0.1l-3,6l-2.8-1.4l-1.2,1.5l-7.9,15.4l4.7,2.5l-2.4,4.8l15,7.7l1.6-0.7l1.1-2.3
                        l6.3,3.2l0.2-0.4l0.6,0.3l0.4-0.8l1.1,0.7l-0.7,1.4l7,3.6l-1.5,2.9l15,7.7l2.9-0.1l1.6-3.2l2,1l3.1-0.2l7.9-15.7l-3.4-1.8l3.4-6.7
                        l-0.6-0.3l0.7-1.6l0.1,0.1l0.5-0.9l0.9,0.5l2.5-5.1l1.2,0.6l3.6-1.4l8-15.6l-4.6-2.5l0.3-0.6l-0.6-0.4l1.8-3.5l-15.8-7.9l-3.5,2.5
                        l-7.5-3.7l0.3-0.5l-6.8-3.5l1.8-3.6L249.3,162.8z"/>
                      <path 
                      onClick={() => {
                        handleObjectFilter('Objekti 5')
                      }}
                      onMouseEnter={() => {
                        document.getElementById('txt5').classList.add('object-filter-text-active')
                      }}
                      onMouseLeave={() => {
                        document.getElementById('txt5').classList.remove('object-filter-text-active')
                      }} className={checkedFilter.find((item) => item.name.includes('Objekti 5') && item.isChecked) ? 'object-filter-selected' : 'object-filter-select'} d="M141.2,131.7l-1.5,3.2l-0.6,2.3l-4.8-1.2l-1,2.7c0,0-4.4,17.1-4.4,17c0.1,0,3.3,0.8,3.3,0.8s-1.6,6.5-1.6,6.5
                        c0.1,0,1.9,0.5,1.9,0.5l-0.2,0.8L131,164l-0.2,0.9l-0.3-0.1l-1.6,6.5l-3.1-0.8l-0.8,1.7l-4.2,16.7l5.1,1.4l-1.3,5.2l16.3,4.2l1.4-1
                        l0.6-2.5l6.8,1.7l0.1-0.5l0.6,0.1l0.2-0.8l1.3,0.4l-0.4,1.5l7.7,2l-0.8,3.2l16.3,4.2l2.8-0.7l0.9-3.5l2.2,0.6l3-0.9l4.2-17l-3.7-1
                        l1.8-7.3l-0.6-0.2l0.4-1.7l0.2,0.1l0.3-1l1,0.2l1.4-5.5l1.3,0.3l3.2-2.2l4.3-17l-5-1.4l0.2-0.7l-0.7-0.2l0.9-3.8l-17.2-4.2l-2.9,3.2
                        l-8.1-1.9l0.1-0.6l-7.4-1.9l0.9-3.9L141.2,131.7z"/>
                      <path 
                      onClick={() => {
                        handleObjectFilter('Objekti 1')
                      }}
                      onMouseEnter={() => {
                        document.getElementById('txt1').classList.add('object-filter-text-active')
                      }}
                      onMouseLeave={() => {
                        document.getElementById('txt1').classList.remove('object-filter-text-active')
                      }} className={checkedFilter.find((item) => item.name.includes('Objekti 1') && item.isChecked) ? 'object-filter-selected' : 'object-filter-select'} d="M344.7,47.9l-0.6,3.5v2.3h-4.9l-0.3,2.9c0,0-0.2,17.6-0.2,17.6c0.1,0,3.4,0,3.4,0s0,6.7,0,6.7c0.1,0,2,0,2,0
                        v0.8h-1.6l0,1h-0.3l0,6.7h-3.1l-0.4,1.9l-0.1,17.3l5.3,0.1l0,5.4l16.9,0.1l1.1-1.4v-2.5l7.1,0v-0.5l0.7,0v-0.9l1.3,0.1v1.6l7.9,0.1
                        v3.3l16.9,0.2l2.6-1.4v-3.6l2.3,0l2.7-1.6V90l-3.8,0v-7.5l-0.7,0l0-1.7l0.2,0l0-1h1V74l1.3,0l2.6-2.9l0.1-17.6l-5.2-0.1l0-0.7
                        l-0.7,0v-4h-17.7l-2,3.8l-8.3,0.1V52l-7.7-0.1l0-4H344.7z"/>
                      <path 
                      onClick={() => {
                        handleObjectFilter('Objekti 8')
                      }}
                      onMouseEnter={() => {
                        document.getElementById('txt8').classList.add('object-filter-text-active')
                      }}
                      onMouseLeave={() => {
                        document.getElementById('txt8').classList.remove('object-filter-text-active')
                      }} className={checkedFilter.find((item) => item.name.includes('Objekti 8') && item.isChecked) ? 'object-filter-selected' : 'object-filter-select'} d="M392.8,147.4l1.5,3.2l1.4,1.9l-3.9,2.9l1.4,2.5c0,0,10.2,14.4,10.3,14.3c0-0.1,2.8-2,2.8-2s3.9,5.4,4,5.4
                        c0.1-0.1,1.6-1.2,1.6-1.2l0.5,0.7l-1.3,1l0.6,0.8l-0.2,0.2l4,5.4l-2.5,1.9l0.8,1.7l10.1,14l4.4-3l3.2,4.4l13.7-9.9l0.1-1.8l-1.5-2
                        l5.7-4.1l-0.3-0.4l0.5-0.4l-0.5-0.7l1.1-0.7l0.9,1.3l6.4-4.6l1.9,2.6l13.7-9.8l1.2-2.7l-2.1-2.9l1.8-1.3l1.2-2.9l-10.4-14.1
                        l-3.1,2.2l-4.4-6l-0.6,0.4l-1.1-1.4l0.1-0.1l-0.6-0.8l0.8-0.6l-3.4-4.6l1.1-0.8l0.4-3.9L444,116.9l-4.3,3l-0.4-0.6l-0.6,0.4
                        l-2.3-3.2L422.1,127l0.6,4.3l-6.7,5l-0.4-0.5l-6.2,4.5l-2.4-3.2L392.8,147.4z"/>
                      <path 
                      onClick={() => {
                        handleObjectFilter('Objekti 3')
                      }}
                      onMouseEnter={() => {
                        document.getElementById('txt3').classList.add('object-filter-text-active')
                      }}
                      onMouseLeave={() => {
                        document.getElementById('txt3').classList.remove('object-filter-text-active')
                      }}className={checkedFilter.find((item) => item.name.includes('Objekti 3') && item.isChecked) ? 'object-filter-selected' : 'object-filter-select'} d="M145,48.2l-0.6,3.5v2.3h-4.9l-0.3,2.9c0,0-0.2,17.6-0.2,17.6s3.4,0,3.4,0s0,6.7,0,6.7c0.1,0,2,0,2,0V82h-1.6
                        l0,1h-0.3l0,6.7h-3.1l-0.4,1.9l-0.1,17.3l5.3,0.1l0,5.4l16.9,0.1l1.1-1.4v-2.5l7.1,0v-0.5l0.7,0v-0.9l1.3,0.1v1.6l7.9,0.1v3.3
                        l16.9,0.2l2.6-1.4v-3.6l2.3,0l2.7-1.6V90.3l-3.8,0v-7.5l-0.7,0l0-1.7l0.2,0l0-1h1v-5.7l1.3,0l2.6-2.9l0.1-17.6l-5.2-0.1l0-0.7
                        l-0.7,0v-4h-17.7l-2,3.8l-8.3,0.1v-0.6l-7.7-0.1l0-4H145z"/>
                      <path 
                      onClick={() => {
                        handleObjectFilter('Objekti 4')
                      }}
                      onMouseEnter={() => {
                        document.getElementById('txt4').classList.add('object-filter-text-active')
                      }}
                      onMouseLeave={() => {
                        document.getElementById('txt4').classList.remove('object-filter-text-active')
                      }} className={checkedFilter.find((item) => item.name.includes('Objekti 4') && item.isChecked) ? 'object-filter-selected' : 'object-filter-select'} d="M28.8,135.9l3,1.9l2.2,0.9l-1.8,4.6l2.5,1.3c0,0,16.3,6.7,16.3,6.6s1.3-3.2,1.3-3.2s6.2,2.5,6.2,2.4
                        c0-0.1,0.7-1.8,0.7-1.8l0.8,0.3l-0.6,1.5l0.9,0.3l-0.1,0.3l6.2,2.4l-1.2,2.9l1.6,1.1l16,6.5l2.1-4.9l5,2l6.3-15.6l-0.8-1.6l-2.4-0.9
                        l2.6-6.6l-0.5-0.2l0.2-0.6l-0.8-0.3l0.6-1.2l1.4,0.6l3-7.3l3,1.2l6.4-15.6l-0.4-2.9l-3.3-1.3l0.9-2.1l-0.5-3.1l-16.3-6.4l-1.4,3.5
                        l-7-2.8l-0.3,0.6l-1.6-0.6l0.1-0.2l-0.9-0.4l0.4-0.9l-5.3-2.1l0.5-1.2l-1.7-3.5l-16.3-6.6l-2,4.8l-0.6-0.3L52.8,88l-3.7-1.5
                        L42.6,103l2.8,3.3l-3,7.8l-0.6-0.2l-2.9,7.1l-3.8-1.5L28.8,135.9z"/>
                          <text id='txt1' transform="matrix(1 0 0 1 368.2002 84.5194)" className={checkedFilter.find((item) => item.name.includes('Objekti 1') && item.isChecked) ? 'object-filter-text-selected' : 'object-filter-text t1'}>1</text>
                          <text id='txt2' transform="matrix(1 0 0 1 269.9982 84.5194)" className={checkedFilter.find((item) => item.name.includes('Objekti 2') && item.isChecked) ? 'object-filter-text-selected' : 'object-filter-text t2'}>2</text>
                          <text id='txt3' transform="matrix(1 0 0 1 168.4557 84.8718)" className={checkedFilter.find((item) => item.name.includes('Objekti 3') && item.isChecked) ? 'object-filter-text-selected' : 'object-filter-text t3'}>3</text>
                          <text id='txt4' transform="matrix(1 0 0 1 65.8456 126.8502)" className={checkedFilter.find((item) => item.name.includes('Objekti 4') && item.isChecked) ? 'object-filter-text-selected' : 'object-filter-text t4'}>4</text>
                          <text id='txt5' transform="matrix(1 0 0 1 156.0076 173.4133)" className={checkedFilter.find((item) => item.name.includes('Objekti 5') && item.isChecked) ? 'object-filter-text-selected' : 'object-filter-text t5'}>5</text>
                          <text id='txt6' transform="matrix(1 0 0 1 256.1687 208.1089)" className={checkedFilter.find((item) => item.name.includes('Objekti 6') && item.isChecked) ? 'object-filter-text-selected' : 'object-filter-text t6'}>6</text>
                          <text id='txt7' transform="matrix(1 0 0 1 351.1552 205.2526)" className={checkedFilter.find((item) => item.name.includes('Objekti 7') && item.isChecked) ? 'object-filter-text-selected' : 'object-filter-text t7'}>7</text>
                          <text id='txt8' transform="matrix(1 0 0 1 431.4968 158.8654)" className={checkedFilter.find((item) => item.name.includes('Objekti 8') && item.isChecked) ? 'object-filter-text-selected' : 'object-filter-text t8'}>8</text>
                    </svg>
                  </StyledMenu>
                  <StyledMenu
                    anchorEl={filter["Floor"].anchorEl}
                    widthNumber={350}
                    open={filter["Floor"].opened}
                    onClose={() => {
                      setFilter({
                        ...filter,
                        Floor: { ...filter["Floor"], opened: false },
                      });
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            label="Përdhesë"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  dispatch(
                                    handleFilter({
                                      name: e.target.name,
                                      isChecked: e.target.checked,
                                    })
                                  );
                                }}
                                name="Përdhesë"
                                checked={
                                  checkedFilter.find(
                                    (item) => item.name === "Përdhesë"
                                  )
                                    ? checkedFilter.find(
                                        (item) => item.name === "Përdhesë"
                                      ).isChecked
                                    : false
                                }
                              />
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormControlLabel
                            label="Suteren"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  dispatch(
                                    handleFilter({
                                      name: e.target.name,
                                      isChecked: e.target.checked,
                                    })
                                  );
                                }}
                                name="Suteren"
                                checked={
                                  checkedFilter.find(
                                    (item) => item.name === "Suteren"
                                  )
                                    ? checkedFilter.find(
                                        (item) => item.name === "Suteren"
                                      ).isChecked
                                    : false
                                }
                              />
                            }
                          />
                        </FormGroup>
                      </Box>
                      <Box>
                        <FormGroup>
                          <FormControlLabel
                            label="Kati 1"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  dispatch(
                                    handleFilter({
                                      name: e.target.name,
                                      isChecked: e.target.checked,
                                    })
                                  );
                                }}
                                name="Kati 1"
                                checked={
                                  checkedFilter.find(
                                    (item) => item.name === "Kati 1"
                                  )
                                    ? checkedFilter.find(
                                        (item) => item.name === "Kati 1"
                                      ).isChecked
                                    : false
                                }
                              />
                            }
                          />
                        </FormGroup>
                        <FormGroup>
                          <FormControlLabel
                            label="Bodrum"
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  dispatch(
                                    handleFilter({
                                      name: e.target.name,
                                      isChecked: e.target.checked,
                                    })
                                  );
                                }}
                                name="Bodrum"
                                checked={
                                  checkedFilter.find(
                                    (item) => item.name === "Bodrum"
                                  )
                                    ? checkedFilter.find(
                                        (item) => item.name === "Bodrum"
                                      ).isChecked
                                    : false
                                }
                              />
                            }
                          />
                        </FormGroup>
                      </Box>
                    </Box>
                  </StyledMenu>
                  <StyledMenu
                    anchorEl={filter["Square"].anchorEl}
                    widthNumber={350}
                    open={filter["Square"].opened}
                    onClose={() => {
                      setFilter({
                        ...filter,
                        Square: { ...filter["Square"], opened: false },
                      });
                    }}
                  >
                    <Typography variant="h6">Siperfaqja</Typography>
                    <SliderComponent />
                  </StyledMenu>
                </Box>
                <Box display={"flex"} justifyContent={"flex-end"} gap={1}>
                  <Button
                    onClick={() => {
                      dispatch(handleResetFilter());
                    }}
                    sx={{
                      mt: 2,
                      color: mainDarkColor,
                      borderColor: mainDarkColor,
                      display: {
                        xs: "flex",
                        md: "none",
                      },
                    }}
                    startIcon={<Refresh />}
                    variant="outlined"
                  >
                    Reseto Filterin
                  </Button>
                  <Button
                    onClick={() => {
                      dispatch(handleMobileModal(false));
                    }}
                    sx={{
                      mt: 2,
                      color: 'orange',
                      backgroundColor: '#1D2934',
                      p: '15px 25px 15px 25px',
                      display: {
                        xs: "flex",
                        md: "none",
                      },
                    }}
                    startIcon={<FilterAlt />}
                    variant="contained"
                  >
                    Filtro
                  </Button>
                </Box>
              </Box>
            </Fade>
          </Modal>
        </>
      );
}

export default MobileFilter