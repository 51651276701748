import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Card from '../../../components/card/Card';
import { useDispatch, useSelector } from 'react-redux';
import { getFilterCheckedData, getFilterSquareData, handleFilteredLength } from '../../../features/filter/FilterSlice';
import { getSvgData } from '../../../features/afarizmi/AfarizmiSlice';


const GridView = () => {
  const checkedFilter = useSelector(getFilterCheckedData)
  const squareFilter = useSelector(getFilterSquareData)
  const svgData = useSelector(getSvgData);
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [sortedArray, setSortedArray] = useState([]);

  useEffect(() => {
    let newCount = 0;
    if (sortedArray) {
      sortedArray.forEach((element) => {
          if (
            !element.isSold && (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
              if(it.name.slice(8).includes(element.apartmentNumber) && it.isChecked){
                return true;
              }
            })) && (element.square >= squareFilter.startVal && element.square <= squareFilter.endVal) && 
            (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
              if(it.name.includes(element.floor) && it.isChecked){
                return true;
              }
            }))
            || (checkedFilter.find((it) => it.name.includes('allFloor') && it.isChecked) && checkedFilter.some((it) => {
              if(it.name.slice(8).includes(element.apartmentNumber) && it.isChecked){
                return true;
              }
            }))
            || (checkedFilter.find((it) => it.name.includes('allObj') && it.isChecked) && checkedFilter.some((it) => {
              if(it.name.includes(element.floor) && it.isChecked){
                return true;
              }
            })
           )
          ) {
            newCount++;
          }
        });
    }
    setCount(newCount); // Update the local count state
  }, [sortedArray, checkedFilter, squareFilter]);
  
  // Use the local count state for dispatch
  useEffect(() => {
    dispatch(handleFilteredLength(count));
  }, [dispatch, count]);

  useEffect(() => {
    if(svgData){
      const combined = []
      svgData.forEach((buildings) => {
        buildings.apartmentList?.forEach((item) => {
          combined.push(item)
        })
      })

     setSortedArray(combined)    }
  }, [svgData])
  return (
    <div>

      <Grid container spacing={4} columnSpacing={6}>
        {
            sortedArray?.sort((a, b) => parseInt(a.apartmentNumber) - parseInt(b.apartmentNumber)).map((element, ind) => {
              if(!element.isSold && (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                if(it.name.slice(8).includes(element.apartmentNumber) && it.isChecked){
                  return true;
                }
              })) && (element.square >= squareFilter.startVal && element.square <= squareFilter.endVal) && 
              (checkedFilter.find((it) => it.name === 'all' && it.isChecked) || checkedFilter.some((it) => {
                if(it.name.includes(element.floor) && it.isChecked){
                  return true;
                }
              }))
              || (checkedFilter.find((it) => it.name.includes('allFloor') && it.isChecked) && checkedFilter.some((it) => {
                if(it.name.slice(8).includes(element.apartmentNumber) && it.isChecked){
                  return true;
                }
              }))
              || (checkedFilter.find((it) => it.name.includes('allObj') && it.isChecked) && checkedFilter.some((it) => {
                if(it.name.includes(element.floor) && it.isChecked){
                  return true;
                }
              }))){
                
                return (
                  <Grid key={element.id} item xs={12} md={4} xl={3}>
                    <Card
                      key={element.id}
                      imgUrl={element.imageUrl}
                      square={element.square.toFixed(2) + " m2"}
                      status={"Ne ndertim"}
                      floor={element.floor}
                      name={element.name}
                      item={element}
                      buildingName={"Objekti " + element.apartmentNumber}
                    />
                  </Grid>
                );
              }
            }
            )  }
      </Grid>
    </div>
  );
};

export default GridView